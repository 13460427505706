/* eslint-disable no-irregular-whitespace */
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import codeIcon from '../assets/images/icons/code.svg'
import MetaImage from '../assets/images/meta.png'
import Block from '../components/Block'
import QuoteBlock from '../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import CTASection from '../components/CTASection'
import Customers from '../components/CustomerLogos'
import { Feature } from '../components/Feature'
import { Hero } from '../components/Hero'
import Layout from '../components/Layout'
import { LeadHeading } from '../components/LeadHeading'
import MainDemoRequestCTA from '../components/MainDemoRequestCTA'
import Page from '../components/Page'
import { ReadNext } from '../components/ReadNext'
import ThreeColumns from '../components/ThreeColumns'

const SwarmiaForDevsPage = () => {
  const developerImages = useStaticQuery(graphql`
    query {
      feature1: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-investment-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(relativePath: { eq: "images/developer-overview.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      glance1: file(
        relativePath: { eq: "images/for-developers/plug-in-tools.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      glance2: file(
        relativePath: { eq: "images/for-developers/get-team-insights.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
            height: 300
          )
        }
      }
      glance3: file(
        relativePath: { eq: "images/for-developers/get-actionable-alerts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="Real-time engineering analytics dashboard"
      description="The engineering analytics in Swarmia give your organization the dashboards, insights, and tools you need to drive engineering effectiveness without sacrificing culture or quality."
      metaImage={MetaImage}
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../assets/images/backgrounds/swarmia-space.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>Real-time engineering analytics dashboard</h1>
          <div className="hero-description">
            <p className="large_text">
              The engineering analytics in Swarmia give your organization the
              dashboards, insights, and tools you need to drive engineering
              effectiveness without sacrificing culture or quality.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="padding-bottom-small padding-top-none layout-index">
        <Customers />
        <Block type="padding-top-small padding-bottom-medium">
          <LeadHeading title="Swarmia in a nutshell" />
          <ThreeColumns>
            <div className="columns columns-reverse three-columns">
              <div className="column">
                <h4>
                  Connect the dots between your source code hosting, issue
                  tracker, and chat
                </h4>
                <div>
                  <GatsbyImage
                    image={
                      developerImages.glance1.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Measure the right things on every level of the engineering
                  organization
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance2.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Stay on track with team-wide Working Agreements and Slack
                  nudges
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance3.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <Block size="large" type="padding-bottom-large padding-top-small">
          <LeadHeading
            title="Here’s how it works"
            description="Swarmia helps you not only measure but also improve engineering effectiveness. It does that with a combination of research-backed metrics and developer tooling."
          />
          <div className="features full-width-images" id="features">
            <Feature
              type="feature feature-grid-right"
              heading="Engineering analytics with proven metrics"
              image={developerImages.feature1.childImageSharp.gatsbyImageData}
              imageAlt="Investment insights in Swarmia"
            >
              <p>
                Engineering effectiveness isn’t just one number you can track.
                That’s why Swarmia surfaces a carefully curated set of
                research-backed engineering metrics that give you actionable
                dashboards with engineering analytics.
              </p>
              <p>
                With Swarmia, you can explore metrics across four areas:
                business impact, flow, code quality, and team health — and use
                those insights to identify improvement areas.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="Drive improvement with Working Agreements"
              image={developerImages.feature2.childImageSharp.gatsbyImageData}
              imageAlt="Working Agreements in Swarmia"
            >
              <p>
                The problem with metrics is that they don’t drive action on
                their own. That’s where Swarmia’s Working Agreements come in.
              </p>
              <p>
                They allow teams to adopt proven habits like &quot;avoid working
                alone&quot; and set numeric targets for code reviews, open PRs,
                and more.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Use Developer Overview to facilitate discussions"
              image={developerImages.feature3.childImageSharp.gatsbyImageData}
              imageAlt="Developer Overview to facilitate discussions"
            >
              <p>
                Ditch toxic developer leaderboards. Swarmia’s Developer Overview
                brings visibility into developers’ work in a healthy way,
                enabling engineers and managers to have fact-based discussions
                about their work.
              </p>
              <p>
                Developer Overview is not a tool for stack ranking individuals
                or reducing them into a single number. In fact, the view is
                first and foremost a tool for developers to learn and grow in
                their career. It allows developers and their managers to make
                work visible, visualize activity patterns, and improve focus.
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="roland" />

        <Block type="padding-top-small padding-bottom-large">
          <LeadHeading
            title="Swarmia product principles"
            description="We’ve seen plenty of failed attempts to drive engineering excellence with high-level dashboards, activity leaderboards, and harmful metrics. But since such approaches rarely work, we built Swarmia based on these principles."
          />
          <ThreeColumns>
            <div className="columns three-columns">
              <div className="column">
                <h4>Much more than a dashboard</h4>
                <p>
                  Swarmia is not just an engineering dashboard for the
                  leadership. In addition to research-backed metrics, it also
                  gives teams the tools they need to get a little bit better
                  every day.
                </p>
              </div>
              <div className="column">
                <h4>Every team is different</h4>
                <p>
                  Theres no single right way to build software. Swarmia adapts
                  to every team’s tools, rituals, and challenges, so you can
                  keep working in a way that works for you and your team.
                </p>
              </div>
              <div className="column">
                <h4>Data is a conversation starter</h4>
                <p>
                  Instead of using engineering metrics to find answers, we
                  believe in using them to ask better questions. After all, data
                  can answer the “what” but it will rarely tell you the “why.”
                </p>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <ReadNext
          label="Explore next"
          heading="Engineering metrics"
          icon={codeIcon}
          url="/engineering-metrics/"
        >
          <p className="large_text">
            Just because you can measure something doesn’t mean you should. Here
            are some of the engineering metrics you can (and can’t) measure with
            Swarmia.
          </p>
        </ReadNext>
      </Page>
      <CTASection>
        Swarmia is how the best teams get better. Get started with a demo or
        a 14-day trial.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/measuring-software-development-productivity/',
          '/blog/size-age-culture-productivity/',
          '/blog/balancing-engineering-investments/',
        ]}
        heading="Learn more from our blog"
      />
    </Layout>
  )
}

export default SwarmiaForDevsPage
